import React from "react";
import { Flex, Icon, Popover, StyledLink } from "orcs-design-system";
import { PropTypes } from "prop-types";
import { find, get } from "lodash";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { OBJECTIVE_ATTRIBUTE_TYPE } from "src/consts/objectives";
/**
 * @typedef {{value?: string, attributeType: string}} TeamAttribute
 */

/**
 * @param {TeamAttribute[]} attributes
 * @returns {undefined | TeamAttribute}
 */
export const findURLAttributeFromAttributes = (attributes) => {
  return find(attributes, (attr) => {
    const urlValuePresent = get(attr, "value.length", 0);
    return (
      attr.attributeType === OBJECTIVE_ATTRIBUTE_TYPE.EXTERNAL_URL &&
      urlValuePresent > 0
    );
  });
};

const NameWithExternalUrl = ({
  objective,
  children,
  showExternalUrl = true,
}) => {
  const externalUrl =
    objective.externalUrl ??
    findURLAttributeFromAttributes(objective?.attributes)?.value;

  return (
    <Flex display="inline-flex" alignItems="center">
      <Flex display="inline-flex" alignItems="center">
        {children}
      </Flex>
      {externalUrl && showExternalUrl && (
        <Popover
          direction="right"
          text={`Open '${externalUrl}' in new window`}
          width="fit-content"
          textAlign="center"
          inlineBlock
        >
          <StyledLink
            href={externalUrl}
            target="_blank"
            onClick={() =>
              trackEvent(EVENT_TRACKING.URL_CLICKED, {
                action: "objective_item_url_clicked",
              })
            }
          >
            <Icon
              icon={["fas", "external-link-alt"]}
              size="xs"
              ml="5px"
              transform="up-2"
            />
          </StyledLink>
        </Popover>
      )}
    </Flex>
  );
};

NameWithExternalUrl.propTypes = {
  objective: PropTypes.object,
  children: PropTypes.node,
  showExternalUrl: PropTypes.bool,
};

export { NameWithExternalUrl };
